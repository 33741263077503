import {HttpErrorResponse} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import {EntitiesChange, EntityAdapter, EntityStore, IdType} from '@axiocode/entity';
import {DataLoaderService, InformationSystemStore} from '@information-system/data';
import {Observable, Subject, distinctUntilChanged, filter, map, tap} from 'rxjs';
import {SubSink} from 'subsink';

import {BranchSwitcherService} from './branch-switcher.service';
import {BranchProvider} from './branch.provider';
import {BranchState} from '../models/branch-state.interface';
import {Branch} from '../models/branch.model';

export function initializeDataLoader(service: DataLoaderService, provider: BranchProvider, store: BranchStore) {
    return () => service.registerLoader(is => provider.findAll$().pipe(tap(data => store.setMany(data))));
}

@Injectable({providedIn: 'root'})
export class BranchStore extends EntityStore<Branch, BranchState> implements OnDestroy {
    #created = new Subject<EntitiesChange<Branch>>();
    get created(): Observable<EntitiesChange<Branch>> {
        return this.#created.asObservable();
    }

    #updated = new Subject<EntitiesChange<Branch>>();
    get updated(): Observable<EntitiesChange<Branch>> {
        return this.#updated.asObservable();
    }

    #deleted = new Subject<EntitiesChange<Branch>>();
    get deleted(): Observable<EntitiesChange<Branch>> {
        return this.#deleted.asObservable();
    }

    #error = new Subject<HttpErrorResponse>();
    get error(): Observable<HttpErrorResponse> {
        return this.#error.asObservable();
    }

    // Selectors
    readonly selectCurrentBranchId$ = this.select(state => state.currentBranchId);
    readonly selectCurrentBranch$ = this.select(
        this.selectAll$,
        this.selectCurrentBranchId$,
        (branches, id) => branches.find(branch => branch.id === id)
    );
    readonly selectCurrentBranchStatus$ = this.select(
        this.selectCurrentBranch$,
        this.selectIds$,
        (branch, ids) => ({branch, known: branch ? ids.includes(branch.id) : false})
    );
    readonly selectBranchesForCurrentIS$ = this.select(
        this.ISStore.selectSelectedEntity$,
        this.selectAll$,
        (IS, branches) => IS ? branches.filter(branch => branch.informationSystem.id === IS.id) : []
    );
    readonly selectIsMainBranch$ = this.select(
        this.selectCurrentBranch$,
        branch => branch?.isMainBranch ?? false
    );
    readonly selectNavigableBranches$ = this.select(
        this.selectBranchesForCurrentIS$,
        branches => branches.filter(branch => branch.isMainBranch || !branch.mergeAt)
    );

    // Reducers
    private readonly setCurrentBranchId = this.updater((state, currentBranchId: IdType | undefined) => ({...state, currentBranchId}));

    /** @ignore */
    private subs = new SubSink();
    private storedBranchChanged = new Subject<StorageEvent>();
    private storageCallback = this.notifyBranchChange.bind(this);

    storedBranchChanged$: Observable<Branch> = this.storedBranchChanged.asObservable().pipe(
        filter(event => 'currentBranch' === event.key),
        filter(event => 'undefined' !== event.newValue && !!event.newValue),
        distinctUntilChanged(),
        map((event): Branch => JSON.parse(event.newValue as string)),
        filter(branch => Number(branch.id) !== Number(this.get().currentBranchId)),
    );

    override onSuccess(change: EntitiesChange<Branch>): void {
        switch (change.type) {
            case 'post':
                this.#created.next(change);
                break;

            case 'patch':
                this.#updated.next(change);
                break;

            case 'delete':
                this.#deleted.next(change);
                break;

            default: break;
        }
    }

    override onError(error: HttpErrorResponse): void {
        this.#error.next(error);
    }

    protected getEntityAdapter(): EntityAdapter<Branch, BranchState> {
        return {
            storeName: 'BranchStore',
            initialState: {ids: [], entities: {}},
            selectId: branch => branch.id,
        };
    }

    constructor(provider: BranchProvider, private branchSwitcher: BranchSwitcherService, private ISStore: InformationSystemStore) {
        super(provider);

        let currentBranch: Branch | null = null;
        try {
            currentBranch = JSON.parse(window.localStorage.getItem('currentBranch') ?? '');
        } catch (e) {
            // avoid failed test
        }

        this.subs.sink = this.ISStore.selectSelectedEntity$.pipe(
            // Filter out if the application is the same as the one we already have
            distinctUntilChanged((previous, current) => previous?.id === current?.id),
            // Resets the state as we changed the current application
            tap(() => this.setState(this.adapter.initialState)),
            // Sets the current branch to the current local storage branch (if any), or the main branch of the IS
            tap(IS => this.setCurrentBranchId(null !== currentBranch && IS?.id === currentBranch?.informationSystem.id ? currentBranch?.id : IS?.mainBranch.id)),
        ).subscribe();

        this.subs.sink = this.branchSwitcher.branchChanged$.pipe(
            tap(branch => this.setCurrentBranchId(branch.id)),
            tap(branch => window.localStorage.setItem('currentBranch', JSON.stringify(branch))),
        ).subscribe();

        window.addEventListener('storage', this.storageCallback);
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();
        this.subs.unsubscribe();
        window.removeEventListener('storage', this.storageCallback);
    }

    private notifyBranchChange(event: StorageEvent): void {
        this.storedBranchChanged.next(event);
    }
}
