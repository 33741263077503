import {Injectable, OnDestroy} from '@angular/core';
import {IdType} from '@axiocode/entity';
import {AxioError} from '@axiocode/error-handler';
import {BehaviorSubject, Observable, Subject, catchError, combineLatest, iif, mergeMap, of, switchMap, take, tap} from 'rxjs';
import {SubSink} from 'subsink';

import {InformationSystemProvider} from './information-system.provider';
import {InformationSystemStore} from './information-system.store';
import {InformationSystem} from '../models/information-system.model';
import {Loading} from '../models/loading.iterface';

type LoaderType = (system: InformationSystem) => Observable<unknown>;

@Injectable({providedIn: 'root'})
export class DataLoaderService implements OnDestroy {
    #subs = new SubSink();
    #loaders: LoaderType[] = [];
    #currentSystem$: BehaviorSubject<InformationSystem | undefined> = new BehaviorSubject<InformationSystem | undefined>(undefined);
    #currentBranch$: BehaviorSubject<IdType | undefined> = new BehaviorSubject<IdType | undefined>(undefined);
    #loadingSystem$: BehaviorSubject<Loading> = new BehaviorSubject<Loading>({loading: false, progression: 0});
    #errors$: Subject<AxioError> = new Subject<AxioError>();

    get currentSystem$(): Observable<InformationSystem | undefined> {
        return this.#currentSystem$.asObservable();
    }

    get isLoading$(): Observable<Loading> {
        return this.#loadingSystem$.asObservable();
    }

    get error$(): Observable<AxioError> {
        return this.#errors$.asObservable();
    }

    constructor(
        private store: InformationSystemStore,
        private provider: InformationSystemProvider,
    ) {
        this.#subs.sink = this.store.selectSelectedEntity$.subscribe(is => this.#currentSystem$.next(is));
    }

    ngOnDestroy(): void {
        this.#subs.unsubscribe();
    }

    load(isId: IdType, branchId?: IdType): Observable<InformationSystem | undefined> {
        const currentIS = this.#currentSystem$.value;
        const currentBranch = this.#currentBranch$.value;

        // Check if we already are on this system
        if (currentIS?.id === isId && (!branchId || currentBranch === branchId)) {
            return this.currentSystem$;
        }

        this.#loadingSystem$.next({loading: true, progression: 0});

        return this.provider.findOne$(isId).pipe(
            tap(is => {
                this.#currentBranch$.next(branchId ?? is.mainBranch?.id);
                // This observable pipe manages the dynamic loading of system dependencies and updates
                // the loading progression, without blocking the resolver since it provides the system
                // before the whole initialization process completes.
                this.#subs.sink = of(is).pipe(
                    take(1),
                    tap(() => this.#loadingSystem$.next({...this.#loadingSystem$.value, progression: 30})),
                    tap(is => this.store.upsertOne(is)),
                    tap(is => this.store.setSelected(is)),
                    // If there are loaders, prepare and use them. Otherwise we skip.
                    mergeMap(is => iif(
                        () => this.#loaders.length > 0,
                        combineLatest(this.prepareLoaders(is)).pipe(
                            switchMap(() => of(is)),
                        ),
                        of(is)
                    )),
                    tap(() => this.#loadingSystem$.next({loading: false, progression: 100})),
                ).subscribe();
            }),
            catchError(error => {
                this.#loadingSystem$.next({...this.#loadingSystem$.value, loading: false});

                return of(error);
            }),
        );
    }

    registerLoader(loader: LoaderType): void {
        this.#loaders.push(loader);
    }

    private prepareLoaders(is: InformationSystem): Observable<unknown>[] {
        let observables: Observable<unknown>[] = [];
        for (const loader of this.#loaders) {
            observables.push(loader(is).pipe(
                tap(() => {
                    let progression = this.#loadingSystem$.value.progression;
                    progression += 70 / this.#loaders.length;
                    this.#loadingSystem$.next({...this.#loadingSystem$.value, progression});
                }),
                catchError(() => {
                    this.#errors$.next(new AxioError('ERROR.IS_LOADING_ERROR'));

                    return of([]);
                })
            ));
        }

        return observables;
    }
}
