import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

import {Branch} from '../models/branch.model';

@Injectable({providedIn: 'root'})
export class BranchSwitcherService {
    #branchChanged = new Subject<Branch>();
    get branchChanged$() {
        return this.#branchChanged.asObservable();
    }

    switchBranch(branch: Branch) {
        this.#branchChanged.next(branch);
    }
}
