import {ActorStore} from '@actor/data';
import {Component, ErrorHandler, inject, Inject} from '@angular/core';
import {toSignal} from '@angular/core/rxjs-interop';
import {NavigationEnd, Router} from '@angular/router';
import {AppComponentStore} from '@app-component/data';
import {ApplicationStore} from '@application/data';
import {ErrorHandlerService} from '@axiocode/error-handler';
import {BranchStore, BranchSwitcherService} from '@branch/data';
import {DataModelStore} from '@data-model/data';
import {FeatureStore} from '@feature/data';
import {FormStore} from '@form/data';
import {FunctionalRequirementStore} from '@functional-requirement/data';
import {GlossaryTermStore} from '@glossary-term/data';
import {InformationSystem, InformationSystemStore} from '@information-system/data';
import {NonFunctionalRequirementStore} from '@non-functional-requirement/data';
import {PageStore} from '@page/data';
import {AppRoutes} from '@routing';
import {TableStore} from '@table/data';
import {DrawerService} from '@ui/drawer';
import {NavigationPlanService} from '@ui/navigation-plan';
import {NotificationService} from '@ui/notification';
import {ScrollingService} from '@ui/scrolling';
import {UseCaseStore} from '@use-case/data';
import {AuthStore} from '@user/auth';
import {UserStore} from '@user/data';
import {addTypeToVersionables} from '@versionable/data';
import {combineLatest, distinctUntilChanged, filter, map, switchMap, startWith, take, tap} from 'rxjs';
import {SubSink} from 'subsink';

@Component({
    selector: 'sidebar-sidebar',
    templateUrl: './sidebar.component.html',
})
export class SidebarComponent {
    /** InformationSystem details */
    currentInformationSystem$ = this.informationSystemStore.selectSelectedEntity$;
    applications$ = this.currentInformationSystem$.pipe(
        filter((is): is is InformationSystem => undefined !== is),
        switchMap(is => this.applicationStore.selectApplicationsByIS$(is))
    );

    currentApplication$ = this.applicationStore.selectSelectedEntity$;

    // Peut être à refaire / réutiliser d'une autre manière => copier coller de navigation plan pour la condition sur l'afficahge de la navigation
    #planService = inject(NavigationPlanService);
    #scrollingService = inject(ScrollingService);
    entries$ = combineLatest([this.#planService.entries, this.#scrollingService.scrolling.pipe(startWith(0))]).pipe(
        map(([entries, _]) => entries.map(entry => ({...entry, visible: this.#scrollingService.isElementVisible(entry.element)}))),
        distinctUntilChanged(),
    );

    selectIsLoggedIn$ = this.authStore.selectIsLoggedIn$;


    vm$ = combineLatest([
        // this.toolbarStore.selectIsLoggedIn$,
        this.ISStore.selectSelectedEntity$,
        this.userStore.selectCurrentUser$,
        this.applicationStore.selectSelectedEntity$,
        this.currentInformationSystem$,
        this.applications$,
        this.branchStore.selectCurrentBranch$,
        this.actorStore.selectActorsForCurrentIS$,
        this.dataModelStore.selectDataModelsForCurrentIS$,
        this.glossaryTermStore.selectTermsForCurrentIS$,
        this.featureStore.selectFeaturesForCurrentIS$,
        this.featureStore.selectFeaturesForCurrentApplication$,
        this.functionalRequirementStore.selectFunctionalRequirementsForCurrentApplication$,
        this.nonFunctionalRequirementStore.selectNonFunctionalRequirementsForCurrentApplication$,
        this.pageStore.selectPagesForCurrentApplication$,
        this.useCaseStore.selectUseCasesForCurrentApplication$,
        this.formStore.selectFormsForCurrentApplication$,
        this.tableStore.selectTablesForCurrentApplication$,
        this.appComponentStore.selectAppComponentsForCurrentApplication$,
        this.authStore.selectIsLoggedIn$,
    ], (currentIS, currentUser, currentApplication, informationSystem, applications, currentBranch, actors, dataModels, terms, featuresIS, featuresApp, funcreqs, nonfuncreqs, pages, usecases, forms, tables, appcomponents, isLoggedIn) => ({
        currentIS, currentUser, currentApplication, informationSystem, applications, currentBranch, actors, dataModels, terms, featuresIS, featuresApp, funcreqs, nonfuncreqs, pages, usecases, forms, tables, appcomponents, isLoggedIn
    }));

    lastUpdatedVersionables$ = this.vm$.pipe(
        map(({actors, dataModels, terms}) =>
            [
                ...addTypeToVersionables(actors, 'actor'),
                ...addTypeToVersionables(dataModels, 'datamodel'),
                ...addTypeToVersionables(terms, 'glossaryterm'),
            ]
        ),
        map(allVersionables => allVersionables.sort((a, b) => a.updatedAt < b.updatedAt ? 1 : -1)), // Order is descending
        map(allVersionables => allVersionables.slice(0, 50))
    );

    // createAction = inject(BranchCreateActionService);

    currentUrl = toSignal(this.router.events.pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd),
        map(event => event.url)
    ));


    #subs = new SubSink();

    constructor(
        private informationSystemStore: InformationSystemStore,
        private applicationStore: ApplicationStore,
        private branchStore: BranchStore,
        private branchSwitcher: BranchSwitcherService,
        private actorStore: ActorStore,
        private dataModelStore: DataModelStore,
        private glossaryTermStore: GlossaryTermStore,
        private featureStore: FeatureStore,

        private appComponentStore: AppComponentStore,
        private formStore: FormStore,
        private functionalRequirementStore: FunctionalRequirementStore,
        private nonFunctionalRequirementStore: NonFunctionalRequirementStore,
        private pageStore: PageStore,
        private useCaseStore: UseCaseStore,
        private tableStore: TableStore,

        // private toolbarStore: ToolbarLocalStore, // no provider defined
        private userStore: UserStore,
        private ISStore: InformationSystemStore,
        private authStore: AuthStore,

        // @Inject(InformationSystemLocalStore) private localStore: InformationSystemLocalStore,
        private notificationService: NotificationService,
        @Inject(ErrorHandler) private errorHandler: ErrorHandlerService,
        public routes: AppRoutes,
        public router: Router,
        private drawerService: DrawerService,
    ) {
        // Deletion handler
        this.informationSystemStore.deleted.pipe(
            take(1),
            tap(() => {
                this.notificationService.notifySuccess('INFORMATION_SYSTEM.INFORMATION_SYSTEM_DELETE_SUCCESS');
                this.routes.organization.list().navigate();
            })
        ).subscribe();
        this.branchStore.deleted.pipe(
            take(1),
            tap(() => {
                this.notificationService.notifySuccess('BRANCH.DELETE_SUCCESS');
                // const is = this.localStore.currentInformationSystem;
                // if (is) {
                //     this.branchSwitcher.switchBranch(is.mainBranch as Branch);
                //     this.routes.informationSystem.details(is.id).navigate();
                // }
            })
        ).subscribe();

        // Error handler
        this.#subs.sink = this.informationSystemStore.error.subscribe(error => this.errorHandler.handleError(error));
        this.#subs.sink = this.branchStore.error.subscribe(error => this.errorHandler.handleError(error));
    }

    checkIsActive(route: string): boolean {
        let url = this.currentUrl();
        if (undefined === url) {
            url = this.router.url;
        }

        return route === url;
    }
}
