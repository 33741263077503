import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot} from '@angular/router';
import {OrganizationStore} from '@organization/data';
import {AppRoutes} from '@routing';
import {catchError, of, tap} from 'rxjs';

import {Application} from '../models/application.model';
import {ApplicationProvider} from '../services/application.provider';
import {ApplicationStore} from '../services/application.store';

/**
 * This resolver fetches the application from the path parameter "applicationId" and sets the application as the current one.
 */
export const applicationResolver: ResolveFn<Application | undefined | null> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const applicationProvider = inject(ApplicationProvider);
    const applicationStore = inject(ApplicationStore);
    const id = route.paramMap.get('applicationId');
    const organizationStore = inject(OrganizationStore);
    const routes = inject(AppRoutes);

    if (null === id) {
        return null;
    }

    organizationStore.findAll();

    return applicationProvider.findOne$(id).pipe(
        tap(application => applicationStore.setSelected(application ?? undefined)),
        catchError(() => {
            routes.error404.navigate({skipLocationChange: true});

            return of(null);
        })
    );
};
